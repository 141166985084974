import React, {useEffect} from "react";
import Feed from "./Feed";
import { auth } from "./firebase";


const App = (props) => {

  useEffect(() => {
    // onAuthStateChangedはユーザー認証変化時にがあったら実行、[user]内に格納
    // userに情報が入っていたらApp, 空であればLoginに
    const unSub = auth.onAuthStateChanged((user) => {
      !user && props.history.push("login");
    });
    return () => unSub();
  });

  return (
    <div className='main'>
      <button
        onClick={async()=>{
          try{
            await auth.signOut();
            props.history.push("login");
          } catch (error){
            alert(error.message);
          }
        }}>
        ログアウト
      </button>
      <h1>検査結果 Result</h1>
      <Feed />
      {/* <p className="text-left">
        ●東京、神奈川、埼玉の店舗及び松戸駅前店にて受検された陽性者の方へ<br />    
        陽性の方には、提携医療機関や保健所から連絡がきますので、必ずご対応をお願いいたします。<br />
        陽性者が急増しているよう場合、連絡にお時間がかかる場合がございます。<br />
        また陽性者が極端に多いときは、連絡が２～３日後になる場合や、無症状者の方には連絡がない場合もございますので、ご了承くださいませ。（高齢者や、症状の重い方を優先して連絡・対応する為）<br />
        対応に関して、詳しくはお住いの都道府県や厚生労働省のホームページをご覧ください。<br />
        ●上記以外の方はお住まいの地域の発熱相談コールセンター等にご連絡のうえ、医療機関を受診してください。
      </p> */}
      <p>●厚生労働省の『新型コロナウイルスに関するQ&amp;A』は<a href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/dengue_fever_qa_00001.html">こちら</a></p>
    </div>
  );


};
export default App;