//更新日入れる？

import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import "./style.css";
import firebase from 'firebase/app';
//テーブル作成用
//import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';



const Feed = () => {
    // firebaseに作成した項目を受け取るためのuseState
    const [posts,setPosts] = useState([
        {
            client_id: "",
            client_phone: "",
            client_result: "",
            client_certificate_type: 0,
            timestamp_result: null,
        }
    ])
    useEffect(() => {
        // firestoreのデータ更新時に反映
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // TODO::検査結果が出てない時の表示
                const firebaseData =db
                    .collection("client")
                    .where("client_phone_key", "==", user.phoneNumber)
                    .orderBy("timestamp_result", "desc")
                    .onSnapshot((snapshot) =>
                        setPosts(
                            snapshot.docs.map((doc) => {
                                var timestamp =""
                                if (doc.data().timestamp_result !== "")
                                {
                                    const d = new Date(doc.data().timestamp_result.seconds *1000);
                                    const year = d.getFullYear();
                                    const month = (`0${d.getMonth() + 1}`).slice(-2);
                                    const day = (`0${d.getDate()}`).slice(-2);
                                    const hour = (`0${d.getHours()}`).slice(-2);
                                    const min = (`0${d.getMinutes()}`).slice(-2);
                                    //const sec = (`0${d.getSeconds()}`).slice(-2);
                                    //timestamp = `${year}-${month}-${day} ${hour}:${min}`;
                                    timestamp = `${year}/${month}/${day}`;
                                }
                                var format_sampling_date=""
                                if (doc.data().client_sampling_date !== "")
                                {
                                    const d = new Date(doc.data().client_sampling_date);
                                    const year = d.getFullYear();
                                    const month = (`0${d.getMonth() + 1}`).slice(-2);
                                    const day = (`0${d.getDate()}`).slice(-2);
                                    const hour = (`0${d.getHours()}`).slice(-2);
                                    const min = (`0${d.getMinutes()}`).slice(-2);
                                    //const sec = (`0${d.getSeconds()}`).slice(-2);
                                    //timestamp = `${year}-${month}-${day} ${hour}:${min}`;
                                    format_sampling_date = `${year}/${month}/${day}`;
                                }
                                var clientResultJa = '';
                                var clientResultBool = 0;
                                if (doc.data().client_result === "TRUE") {
                                    clientResultJa = "陽性";
                                    clientResultBool = 1;
                                } else if (doc.data().client_result === "FALSE") {
                                    clientResultJa = "陰性";
                                } else {
                                    clientResultJa = "問い合わせ";
                                }
                                var kakuinFile = '';
                                switch (doc.data().client_certificate_clinic_id) {
                                    case '1':
                                        if (new Date(doc.data().client_analysis_date) >= new Date('2023/11/06 00:00:00')) {
                                            kakuinFile = 'kawasakikensa_kakuin.png'
                                        } else {
                                            kakuinFile = 'ekimae_kakuin.png'
                                        }
                                        break;
                                    case '2':
                                        kakuinFile = 'fujisawa_kakuin.png'
                                        break;
                                    case '3':
                                        kakuinFile = 'yokohama_kakuin.png'
                                        break;
                                    case '4':
                                        kakuinFile = 'musashikosugi_kakuin.png'
                                        break;
                                    case '5':
                                        kakuinFile = 'kawasakikensa_ueno_kakuin.png'
                                        break;
                                    case '6':
                                        kakuinFile = 'hiratsuka_kakuin.png'
                                        break;
                                    case '7':
                                        kakuinFile = 'ichikawa_kakuin.png'
                                        break;
                                    case '8':
                                        kakuinFile = 'yamato_kakuin.png'
                                        break;
                                    case '9':
                                        kakuinFile = 'shibuya_kakuin.png'
                                        break;
                                    case '10':
                                        kakuinFile = 'akabane_kakuin.png'
                                        break;
                                    case '11':
                                        kakuinFile = 'urawa_kakuin.png'
                                        break;
                                    case '12':
                                        kakuinFile = 'roppongi_kakuin.png'
                                        break;
                                    case '13':
                                        kakuinFile = 'kawagoe_kakuin.png'
                                        break;
                                    case '14':
                                        kakuinFile = 'sugamo_kakuin.png'
                                        break;
                                    case '15':
                                        kakuinFile = 'sakurashinmachi_kakuin.png'
                                        break;
                                    case '16':
                                        kakuinFile = 'kojimachi_kakuin.png'
                                        break;
                                    case '17':
                                        kakuinFile = 'biotope_kakuin.png'
                                        break;
                                    case '18':
                                        kakuinFile = 'kokubunji_kakuin.png'
                                        break;
                                    case '19':
                                        kakuinFile = 'iidabashi_kakuin.png'
                                        break;
                                    case '20':
                                        kakuinFile = 'kayabacho_kakuin.png'
                                        break;
                                    case '21':
                                        kakuinFile = 'tsurumi_kakuin.png'
                                        break;
                                    case '22':
                                        kakuinFile = 'machida_kakuin.png'
                                        break;
                                    case '23':
                                        kakuinFile = 'gakugeidaigaku_kakuin.png'
                                        break;
                                    case '24':
                                        kakuinFile = 'center_kita_kakuin.png'
                                        break;
                                    case '25':
                                        kakuinFile = 'azabu_kakuin.png'
                                        break;
                                    case '26':
                                        kakuinFile = 'komagome_kakuin.png'
                                        break;
                                    case '27':
                                        kakuinFile = 'matsudo_kakuin.png'
                                        break;
                                    case '28':
                                        kakuinFile = 'tachikawa_kakuin.png'
                                        break;
                                    case '29':
                                        kakuinFile = 'nakano_kakuin.png'
                                        break;
                                    case '30':
                                        kakuinFile = 'goi_kakuin.png'
                                        break;
                                    case '31':
                                        kakuinFile = 'kasukabe_kakuin.png'
                                        break;
                                    case '32':
                                        kakuinFile = 'kamoi_kakuin.png'
                                        break;
                                    case '33':
                                        kakuinFile = 'yokosuka_kakuin.png'
                                        break;
                                    case '34':
                                        kakuinFile = 'honatsugi_kakuin.png'
                                        break;
                                    case '35':
                                        kakuinFile = 'chibachuo_kakuin.png'
                                        break;
                                    case '36':
                                        kakuinFile = 'kisarazu_kakuin.png'
                                        break;
                                    case '37':
                                        kakuinFile = 'chibafunabashi_kakuin.png'
                                        break;
                                    case '38':
                                        kakuinFile = 'omori_kakuin.png'
                                        break;
                                    case '39':
                                        kakuinFile = 'meguro_kakuin.png'
                                        break;
                                    case '40':
                                        kakuinFile = 'nerima_kakuin.png'
                                        break;
                                    case '41':
                                        kakuinFile = 'ookayama_kakuin.png'
                                        break;
                                    case '42':
                                        kakuinFile = 'fuchu_kakuin.png'
                                        break;
                                    case '43':
                                        kakuinFile = 'yotsuya_kakuin.png'
                                        break;
                                    case '44':
                                        kakuinFile = 'hachioji_kakuin.png'
                                        break;
                                    case '45':
                                        kakuinFile = 'kawasakikensa_ueno_kakuin.png'
                                        break;
                                    case '46':
                                        kakuinFile = 'tsujido_kakuin.png'
                                        break;
                                    case '47':
                                        kakuinFile = 'asakusa_kakuin.png'
                                        break;
                                    case '48':
                                        kakuinFile = 'kamakura_kakuin.png'
                                        break;
                                    case '49':
                                        kakuinFile = 'kanayama_kakuin.png'
                                        break;
                                    case '50':
                                        kakuinFile = 'ichinomiya_kakuin.png'
                                        break;
                                    case '51':
                                        kakuinFile = 'okayama_kakuin.png'
                                        break;
                                    case '52':
                                        kakuinFile = 'hamamatsu_kakuin.png'
                                        break;
                                    case '53':
                                        kakuinFile = 'kurashiki_kakuin.png'
                                        break;
                                    case '54':
                                        kakuinFile = 'kariya_kakuin.png'
                                        break;
                                    case '55':
                                        kakuinFile = 'toyohashi_kakuin.png'
                                        break;
                                    case '56':
                                        kakuinFile = 'kusatsu_kakuin.png'
                                        break;
                                    case '57':
                                        kakuinFile = 'gifu_kakuin.png'
                                        break;
                                    case '58':
                                        kakuinFile = 'nagoya_kakuin.png'
                                        break;
                                    case '59':
                                        kakuinFile = 'sakae_kakuin.png'
                                        break;
                                    case '60':
                                        kakuinFile = 'shizuoka_kakuin.png'
                                        break;
                                    case '61':
                                        kakuinFile = 'numazu_kakuin.png'
                                        break;
                                    case '62':
                                        kakuinFile = 'kawasakikensa_ueno_kakuin.png'
                                        break;
                                    case '63':
                                        kakuinFile = 'toyota_kakuin.png'
                                        break;
                                    case '64':
                                        kakuinFile = 'minamiotsuka_kakuin.png'
                                        break;
                                    case '65':
                                        kakuinFile = 'akasaka_kakuin.png'
                                        break;
                                    case '66':
                                        kakuinFile = 'kokuraminamiku_kakuin.png'
                                        break;
                                    case '67':
                                        kakuinFile = 'hiyoshi_kakuin.png'
                                    case '68':
                                        kakuinFile = 'yokohama_kakuin.png'
                                        break;
                                    case '76':
                                        kakuinFile = 'chuorinkan_kakuin.png'
                                        break;
                                    default:
                                        kakuinFile = 'other.png'
                                        break;
                                }

                                var certType = doc.data().client_certificate_type;
                                if(
                                    // 神奈川もしくは松戸以外の千葉店舗の陽性
                                    (clientResultBool == 1 && doc.data().client_store_pref == "神奈川県")
                                    ||(clientResultBool == 1 && doc.data().client_store_pref == "千葉県" && doc.data().client_store_id != 31)
                                    ){
                                        // 検査タイプが無料PCRもしくは無料抗原の時は、無料用の証明書を表示するようにする
                                        if(doc.data().client_inspection_type == 4 || doc.data().client_inspection_type == 5){
                                            certType = "4"
                                        }else{
                                            certType = "1"
                                        }
                                }

                                // 松戸独自の無料検査の場合は
                                if(doc.data().client_store_id == 31 && doc.data().client_free_type == "松戸市" && certType == "4"){
                                    certType = "1"
                                }

                                return {
                                    client_id: doc.data().client_id,
                                    client_phone_key: doc.data().client_phone_key,
                                    client_phone_show: doc.data().client_phone_show,
                                    client_result: clientResultJa,
                                    client_result_bool: clientResultBool,
                                    client_certificate_type: certType, // 1.通常証明書, 2.不要, 3.海外渡航, 4.無料化事業
                                    client_full_name: doc.data().client_full_name,
                                    client_postal_code: doc.data().client_postal_code,
                                    client_address: doc.data().client_address,
                                    client_sex: doc.data().client_sex,
                                    client_dob: doc.data().client_dob,
                                    client_analysis_date: doc.data().client_analysis_date,
                                    client_sampling_date: doc.data().client_sampling_date,
                                    client_certificate_clinic_id: doc.data().client_certificate_clinic_id,
                                    client_passport_number: doc.data().client_passport_number,
                                    client_nation: doc.data().client_nation,
                                    client_need_time: doc.data().client_need_time,
                                    client_inspection_type: doc.data().client_inspection_type,
                                    client_store_id: doc.data().client_store_id,
                                    client_store_pref: doc.data().client_store_pref,
                                    kakuin_file: kakuinFile,
                                    timestamp_result: timestamp,
                                    format_sampling_date: format_sampling_date,
                                }
                            })
                        )
                    );
                    //クリーンアップ関数
                    return () => {
                        firebaseData();
                    };
            }
        });
    },[])

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>番号</TableCell>
                        {/* <TableCell align="right">電話番号</TableCell> */}
                        <TableCell align="right">結果</TableCell>
                        <TableCell align="right">証明書</TableCell>
                        <TableCell align="right">検査日</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {posts.map((postItem) => (
                        <TableRow key={postItem.client_id}>
                        <TableCell component="th" scope="row">
                            {postItem.client_id}
                        </TableCell>
                        {/* <TableCell align="right">{postItem.client_phone_show}</TableCell> */}
                        {postItem.client_result == "陽性"
                            ? <TableCell align="right" style={{ color: 'red'}}>{postItem.client_result}</TableCell>
                            : <TableCell align="right" style={{ color: 'black'}}>{postItem.client_result}</TableCell>}
                        <TableCell align="right">
                            {postItem.client_certificate_type === '1' || postItem.client_certificate_type === '4' ?
                            <Link
                                to={{
                                    pathname: '/certificate',
                                    search: `?client_id=${postItem.client_id}
                                                &client_full_name=${postItem.client_full_name}
                                                &client_postal_code=${postItem.client_postal_code}
                                                &client_address=${postItem.client_address}
                                                &client_dob=${postItem.client_dob}
                                                &client_sex=${postItem.client_sex}
                                                &client_analysis_date=${postItem.client_analysis_date}
                                                &client_sampling_date=${postItem.client_sampling_date}
                                                &client_certificate_clinic_id=${postItem.client_certificate_clinic_id}
                                                &client_result_bool=${postItem.client_result_bool}
                                                &client_certificate_type=${postItem.client_certificate_type}
                                                &client_passport_number=${postItem.client_passport_number}
                                                &client_nation=${postItem.client_nation}
                                                &client_need_time=${postItem.client_need_time}
                                                &client_inspection_type=${postItem.client_inspection_type}
                                                &client_store_id=${postItem.client_store_id}
                                                &client_store_pref=${postItem.client_store_pref} 
                                                &kakuin_file=${postItem.kakuin_file}`,
                                }}
                            >表示する</Link> : '-'}
                        </TableCell>
                        {console.log(postItem.client_id)}
                        {console.log(postItem.client_sampling_date)}
                        <TableCell align="right">{postItem.format_sampling_date}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {posts.length === 0 && <p className="text-red">『まだ検査結果が登録されていません。』</p>}
        </div>
    )
}

export default Feed

