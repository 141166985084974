import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Route, BrowserRouter } from "react-router-dom";
import Login from "./Login";
import { PrintPage } from "./print";
ReactDOM.render(
  <BrowserRouter>
    <>
      {/* ログインしたときはAppを表示 */}
      <Route exact path="/" component={App} />
      <Route exact path="/certificate" component={PrintPage} />
      {/* TODO::スマホはリダイレクトする・ */}
      {/* https://qiita.com/tomipetit/items/975eceda3d07b152547c */}
      {/* https://arts-factory.net/user_agent/ */}

      {/* ログインしていないときはLoginを表示 */}
      <Route exact path="/login" component={Login} />
    </>
  </BrowserRouter>,
  document.getElementById("root")
);