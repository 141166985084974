
//患者用のログイン画面
import FirebaseUIAuth from "react-firebaseui-localized";
import firebase from 'firebase/app';
import "firebase/auth";
import { firebaseApp } from "./firebase";

//以下参照：https://qiita.com/cola119/items/99350f2c34c51378777e
const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: "/",
  signInOptions: [
    {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        recaptchaParameters: {
            type: 'image',
            size: 'invisible',
            badge: 'bottomleft'
        },
        defaultCountry: 'JP',
        whitelistedCountries: ['JP', '+81']
    }
  ],
}

const Login = () => {
  return (
      <div className="main">
          <h1>新型コロナPCR検査結果</h1>
          <div className="login-notice-text">
            <p>・検査を受けた際に登録したご自身の電話番号を入力してください。</p>
            <p>・頻回にログインするとセキュリティ対策として、一定時間ログイン操作が行えなくなります。</p>
            <a href="https://bit.ly/3nRqgiP">詳細はこちら</a>
          </div>
          <FirebaseUIAuth lang="ja" config={uiConfig} auth={firebaseApp.auth()} firebase={firebase} />
      </div>
  );
};
export default Login;

