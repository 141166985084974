import React from "react";
import { createGlobalStyle } from "styled-components";
import { createPdfFromHtml } from "./logic";
import { auth } from "./firebase";

const Global = createGlobalStyle`
  /* paper.css */
  https://github.com/cognitom/paper-css

  /* @page { margin: 0 } */
  #print {
    margin: 0;
    font-family: "IPAexGothic", sans-serif;
  }
  .sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
  }


  /** Paper sizes **/
  #print.A3               .sheet { width: 297mm; height: 419mm }
  #print.A3.landscape     .sheet { width: 420mm; height: 296mm }
  #print.A4               .sheet { width: 210mm; height: 296mm }
  #print.A4.landscape     .sheet { width: 297mm; height: 209mm }
  #print.A5               .sheet { width: 148mm; height: 209mm }
  #print.A5.landscape     .sheet { width: 210mm; height: 147mm }
  #print.letter           .sheet { width: 216mm; height: 279mm }
  #print.letter.landscape .sheet { width: 280mm; height: 215mm }
  #print.legal            .sheet { width: 216mm; height: 356mm }
  #print.legal.landscape  .sheet { width: 357mm; height: 215mm }

  /** Padding area **/
  .sheet.padding-5mm { padding: 5mm }
  .sheet.padding-10mm { padding: 10mm }
  .sheet.padding-15mm { padding: 15mm }
  .sheet.padding-20mm { padding: 20mm }
  .sheet.padding-25mm { padding: 25mm }

  /** For screen preview **/
  @media screen {
    body {
      background: #E0E0E0;
      height: 100%;
    }
    .sheet {
      background: #FFFFFF;
      /* margin: 5mm auto; */
      padding: 5mm 0;
    }
  }

  /** Fix for Chrome issue #273306 **/
  @media print {
    #print.A3.landscape            { width: 420mm }
    #print.A3, #print.A4.landscape { width: 297mm }
    #print.A4, #print.A5.landscape { width: 210mm }
    #print.A5                      { width: 148mm }
    #print.letter, #print.legal    { width: 216mm }
    #print.letter.landscape        { width: 280mm }
    #print.legal.landscape         { width: 357mm }
  }
`;

export class PrintPage extends React.Component {
  printContent;
  params = {};

  constructor(props) {
    super(props);
    this.state = {};

    let urlParamStr = decodeURI(window.location.search)
    if (urlParamStr) {
      urlParamStr = urlParamStr.substring(1)
      //let params = {}
      urlParamStr.split('&').forEach(param => {
        const temp = param.split('=')
        //pramsオブジェクトにパラメータを追加
        this.params = {
          ...this.params,
          [temp[0]]: temp[1]
        }
      })
      // console.log(this.params.client_id);
      // console.log(this.params.client_full_name);
      // console.log(this.params.client_postal_code);
      // console.log(this.params.client_address);
      // console.log(this.params.client_dob);
      // console.log(this.params.client_sex);
      // console.log(this.params.client_analysis_date);
      // console.log(this.params.client_sampling_date);
      // console.log(this.params.client_certificate_clinic_id);
      // console.log(typeof this.params.client_result_bool);
    }

    auth.onAuthStateChanged((user) => {
      if (!user ) {
        this.props.history.push('login')
      }
    });
  }

  render() {
    return (
      <>
        <Global />
        <div className="dl-btn-wrapper">
          <button onClick={this.handleClick}>PDFダウンロードはここをクリック</button>
          <p>※パソコンからアクセスして、上記ダウンロードボタンをクリックしてください。</p>
          <p>※ブラウザはchromeの最新バージョンを推奨しております。</p>
          <p>※Internet Explorerでは正常に動作しない場合がございます。Windowsをお使いのお客様はMicrosoft Edgeをご使用ください。</p>
        </div>
        <div id="print" className="A4">
          <Sheet
            client_full_name={this.params.client_full_name}
            client_id={this.params.client_id}
            client_postal_code={this.params.client_postal_code}
            client_address={this.params.client_address}
            client_dob={this.params.client_dob}
            client_sex={this.params.client_sex}
            client_analysis_date={this.params.client_analysis_date}
            client_sampling_date={this.params.client_sampling_date}
            client_certificate_clinic_id={this.params.client_certificate_clinic_id}
            client_result_bool={this.params.client_result_bool}
            client_certificate_type={this.params.client_certificate_type}
            client_passport_number={this.params.client_passport_number}
            client_nation={this.params.client_nation}
            client_need_time={this.params.client_need_time}
            client_inspection_type={this.params.client_inspection_type}
            client_store_id={this.params.client_store_id}
            client_store_pref={this.params.client_store_pref}
            kakuin_file={this.params.kakuin_file}
          />
          <div style={{ position: "absolute", opacity: 0.0, top: 0, zIndex: -9999 }}>
            <div
              ref={(el) => {
                this.printContent = el;
              }}
            >
              <Sheet
                client_full_name={this.params.client_full_name}
                client_id={this.params.client_id}
                client_postal_code={this.params.client_postal_code}
                client_address={this.params.client_address}
                client_dob={this.params.client_dob}
                client_sex={this.params.client_sex}
                client_analysis_date={this.params.client_analysis_date}
                client_sampling_date={this.params.client_sampling_date}
                client_certificate_clinic_id={this.params.client_certificate_clinic_id}
                client_result_bool={this.params.client_result_bool}
                client_certificate_type={this.params.client_certificate_type}
                client_passport_number={this.params.client_passport_number}
                client_nation={this.params.client_nation}
                client_need_time={this.params.client_need_time}
                client_inspection_type={this.params.client_inspection_type}
                client_store_id={this.params.client_store_id}
                client_store_pref={this.params.client_store_pref}
                kakuin_file={this.params.kakuin_file}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  handleClick = () => {
    createPdfFromHtml(this.printContent);
  };
}

const Sheet = (props) => {
  return (
    <div>
      {/* 無料検査 千葉 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("千葉県") != -1
      && (
        <div className="sheet padding-5mm chiba-sheet">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・ この検査結果は、「ワクチン・検査パッケージ制度」や「対象者全員検査」等においてのみ有効です。</span><br />
              <span className="">・入店・入場等の際に、身分証明書とともに提示してください。</span><br />
              <span className="">・本通知書における検査結果は、新型コロナ感染者の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <p className="" style={{ textAlign: 'left', color: 'red' }}>陽性の方は、入場・入店等できません。速やかに医療機関を受診してください。</p>
          </div>
          <div className="main-info">
            <span className="name">□ 受検者氏名：{props.client_full_name}</span><br />
            <span className="">□ 検体採取日：{props.client_sampling_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">□ 検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">□ 有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">□ 検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">□ 検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">□ 使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            {/* <small className="">※1 検査日のみが分かる場合は検査日を記入。抗原定性検査の場合は検査日</small><br />
            <small className="">※2 判定不能は陰性として取り扱うことはできないため、再度の検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※3 有効期限:PCR検査等は採取日+3日、抗原定性検査は採取日+1日</small><br /> */}
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px' }}>
              <span className="">事業所名(又は検査所名)※4：帝国衛生検査所</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            {/* <small className="">※４smallPCR 検査等・抗原定量検査の場合は、検査分析を行った検査所名を記載。</small> */}
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
            <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
                {props.client_store_id != 31 && props.client_store_id != 34 && props.client_store_id != 40 // 松戸、五井、木更津
                  && (
                    <div>
                      <small className="">□ 発熱外来等医療機関を受診してください。</small><br />
                      <small className="">□ 速やかに下記ＨＰを参照のうえ、医療機関を受診してください。タイトル等を検索していただくか、QRコードを読み取ってください。</small><br />
                      <small className="">県ＨＰ：熱があるときはＵＲＬ：https://www.pref.chiba.lg.jp/kenfuku/hatsunetsu.html</small><br />
                      <div style={{ textAlign: 'left'}}>
                        <img className="kakuin" src={`${process.env.PUBLIC_URL}/ichikawa_cert_qr.png`} alt=""></img>
                      </div>
                      <small className="">□ 上記ＨＰの他、次の要件を全て満たす方は下記ＨＰから登録することで医師の診断を受けることができます。</small><br />
                      <div style={{ textAlign: 'left' }}>
                        <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/chiba_info5.png`} alt=""></img>
                      </div>
                    </div>
                )}
                {(props.client_store_id == 31 || props.client_store_id == 34 || props.client_store_id == 40)
                  && (
                    <div>
                      <small className="">□ 提携医療機関から保健所に発生届を提出しますので、お住まいの地域の保健所からショートメッセージ（ＳＭＳ）等でご連絡が入ります。（現在大変混み合っており、かなりのお時間がかかるようです。）</small><br />
                      <small className="">□ 外出を避け、療養をお願いします。</small><br />
                      <small className="">□ 体調の急変等の場合は千葉県発熱相談コールセンター ０５７０－２００－１３９へご相談をいただくか、  医療機関（発熱診療等医療機関）へ事前連絡のうえ、受診してください。</small><br />
                      <small className="">□ 症状が急激に悪化したとき、緊急の対応が必要となった場合は迷わず救急車を要請する等の行動に努めてください。</small><br />
                      <small className="">□ 速やかに下記ＨＰを参照のうえ、医療機関を受診してください。タイトル等を検索していただくか、QRコードを読み取ってください。</small><br />
                      <small className="">県ＨＰ：熱があるときはＵＲＬ：https://www.pref.chiba.lg.jp/kenfuku/hatsunetsu.html</small><br />
                      <div style={{ textAlign: 'left'}}>
                        <img className="kakuin" src={`${process.env.PUBLIC_URL}/ichikawa_cert_qr.png`} alt=""></img>
                      </div>
                      <small className="">□ ご自宅で療養される方は、下記ＨＰを参照してください。</small><br />
                      <small className="">タイトル又はURLを検索していただくか、下のQRコードを読み取ってください。</small><br />
                      <small className="">県ＨＰ：ご自宅で療養される方へ</small><br />
                      <small className="">ＵＲＬ：https://www.pref.chiba.lg.jp/kenzu/kenkouken/jitakuryouyou.html</small><br />
                      <div style={{ textAlign: 'left' }}>
                        <img className="kakuin" src={`${process.env.PUBLIC_URL}/ichikawa_cert_qr2.png`} alt=""></img>
                      </div>
                    </div>
                )}

          </div>
        </div>
      )}

      {/* 無料検査 神奈川 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("神奈川県") != -1
        && (
        <div className="sheet padding-5mm kanagawa-sheet">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・ この検査結果は、「ワクチン・検査パッケージ制度」や「対象者全員検査」等においてのみ有効です。</span><br />
              <span className="">・入店・入場等の際に、身分証明書とともに提示してください。</span><br />
              <span className="">・本通知書における検査結果は、新型コロナ感染者の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <p className="" style={{ textAlign: 'left', color: 'red' }}>陽性の方は、入場・入店等できません。速やかに医療機関を受診してください。</p>
          </div>
          <div className="main-info">
            <span className="name">□ 受検者氏名：{props.client_full_name}</span><br />
            <span className="">□ 検体採取日：{props.client_sampling_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">□ 検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">□ 有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">□ 検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">□ 検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">□ 使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            {/* <small className="">※1 検査日のみが分かる場合は検査日を記入。抗原定性検査の場合は検査日</small><br />
            <small className="">※2 判定不能は陰性として取り扱うことはできないため、再度の検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※3 有効期限:PCR検査等は採取日+3日、抗原定性検査は採取日+1日</small><br /> */}
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px' }}>
              <span className="">事業所名(又は検査所名)※4：帝国衛生検査所</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            {/* <small className="">※４smallPCR 検査等・抗原定量検査の場合は、検査分析を行った検査所名を記載。</small> */}
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
            <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
            <span className="">□ 医療機関（発熱診療等医療機関）を受診してください。</span><br />
            <span className="">□ 専用ダイヤルに電話し受診先について相談してください。</span><br />
            <span className="">□ 神奈川県在住で、発生届対象者以外の方は、陽性者登録＊することで、療養支援を受けることができます。</span><br /><br />

            <span className="">【発生届対象者以外（陽性者登録が可能となる対象者）】</span><br />
            <span className="">神奈川県在住で、「65歳未満」、「妊娠してない」、「重症化リスクがない」すべての条件を満たす方です。</span><br />
            <span className="">神奈川県新型コロナウイルス感染症専用ダイヤル（0570-056-774）、または、神奈川県ホームページ「新型コロナ 陽性者登録窓口について」よりご登録ください。</span><br /><br />
          </div>

          <div className="dial-info" style={{ marginTop: '5px' }}>
            <span className="">【新型コロナウィルス感染症専用ダイヤル】 </span>
            <span className="">お住まいの地域に応じて次の表の電話番号までお問い合わせください。陽性が判明した時は、個別の医療機関への直接の相談はご遠慮ください。</span>
            <table border="1" style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th>市町</th>
                  <th>電話番号</th>
                  <th>受付時間</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>横浜市</td>
                  <td>045-550-5530</td>
                  <td>24時間（毎日）</td>
                </tr>
                <tr>
                  <td>川崎市</td>
                  <td>044-200-0730</td>
                  <td>24時間（毎日）</td>
                </tr>
                <tr>
                  <td>相模原市</td>
                  <td>042-769-9237</td>
                  <td>24時間（毎日）</td>
                </tr>
                <tr>
                  <td>横須賀市</td>
                  <td>046-822-4308</td>
                  <td>8:30 から20:00（平日）<br />
                    9:00 から17:00（土曜・日曜・祝日）</td>
                </tr>
                <tr>
                  <td>藤沢市</td>
                  <td>0466-50-8200</td>
                  <td>24時間（毎日）</td>
                </tr>
                <tr>
                  <td>茅ヶ崎市、寒川町</td>
                  <td>0467-55-5395</td>
                  <td>9:00 から19:00（平日）<br />
                    9:00 から17:00（土曜・祝日）<br />
                    注意：日曜日休み</td>
                </tr>
                <tr>
                  <td>上記以外</td>
                  <td>0570-056774</td>
                  <td>24時間（毎日）</td>
                </tr>
              </tbody>
            </table>
            <small>※  移動の際には、周囲に感染させないようマスクを着用し、公共交通機関の利用は、避けてください。</small>
          </div>
        </div>
      )}

      {/* 無料検査 東京 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("東京都") != -1
        && (
        <div className="sheet padding-5mm tokyo-sheet">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">本通知における検査結果は新型コロナ感染者の患者であるかどうかの診断結果を示すものではありません。</span><br />
            </div>
          </div>
          <div className="main-info">
            <span className="name">□ 受検者氏名：{props.client_full_name}</span><br />
            <span className="">□ 検体採取日※1：{props.client_sampling_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">□ 検査結果※2：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">□ 有効期限※3：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">□ 検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">□ 検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">□ 使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <small className="">※1 検査日のみが分かる場合は検査日を記入。抗原定性検査の場合は検査日</small><br />
            <small className="">※2 判定不能は陰性として取り扱うことはできないため、再度の検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※3 有効期限:PCR検査等は採取日+3日、抗原定性検査は採取日+1日</small><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px' }}>
              <span className="">事業所名(又は検査所名)※4：{props.client_certificate_clinic_id == 64 ? "株式会社ヒューマンプランニング" : "帝国衛生検査所"}</span><br />
              <span className="">検査管理者氏名：{props.client_certificate_clinic_id == 64 ? "大谷 柾樹" : "近都 真侑"}</span>
            </div>
            <small className="">※４ PCR検査等・抗原定量検査の場合は、検査分析を行った検査所名を記載。</small>
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
            <span>【陽性の場合】</span><br />
            <span>（発生届の届け出対象の方） 医療機関を受診してください。</span><br />
            <span>（発生届の届け出対象とならない方※）東京都陽性者登録センターに申請し、陽性の確定診断を受けることができます。（都が実施する 支援のうち配食・パルスオキシメーターの送付、健康観察、療養施設における療養の支援を希望する場合には登録が必要です。）</span><br />
            <small>※ 65歳未満の方、入院を要しない方、コロナ治療薬や酸素投与が必要ない方、妊娠していない方</small><br /><br />
            <span>（参考）</span><br />
            <span>①発生届の届け出対象について</span><br />
            <span>URL：https://www.fukushihoken.metro.tokyo.lg.jp/iryo/kansen/corona_portal/shien/index.html</span><br />
            <div className="">
              <img className="kakuin-all" src={`${process.env.PUBLIC_URL}/${'qr_tokyo_hasseitodoketarget.png'}`} alt="qr code"></img>
            </div>
            <span>②東京都陽性者登録センターについて</span><br />
            <span>URL：https://www.fukushihoken.metro.tokyo.lg.jp/iryo/kansen/corona_portal/shien/youseitouroku.html</span><br />
            <div className="">
              <img className="kakuin-all" src={`${process.env.PUBLIC_URL}/${'qr_tokyo_yoseitorokucenter.png'}`} alt="qr code"></img>
            </div>
          </div>
        </div>
      )}

      {/* 無料検査 埼玉 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("埼玉県") != -1
        && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・この検査結果は、「ワクチン・検査パッケージ制度」等においてのみ有効です。</span><br />
              <span className="">・入店・入場の際に身分証とともに提示してください。</span><br />
              <span className="">・本検査結果通知書は、新型コロナ感染症の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <small className="" style={{ textAlign: 'left' }}>陽性の場合は、入場・入店できません。公共交通機関を避け速やかに医療機関を受診してください。</small>
          </div>
          <div className="main-info">
            <span className="name">受検者氏名：{props.client_full_name}</span><br />
            <span className="">検体採取日：{props.client_sampling_date}</span><br />
            <span className="">検査日：{props.client_analysis_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', paddingLeft: '5px'  }}>
              <span className="">検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px', paddingLeft:'5px' }}>
              <span className="">事業所名(又は検査所名)：帝国衛生検査所</span><br />
              <span className="">事業所電話番号：050-3101-7430</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            <small className="">※1 判定不能は陰性として扱うことはできないため、再度検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※２有効期限：ＰＣＲ検査等は採取日＋３日、抗原定性検査は検査日＋１日</small><br />
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
            <span>検査結果が陽性の場合</span><br />
            <span className="">　医療機関の受診、又は受診・相談センターに電話し受診について相談してください。</span><br />
            <span className="">　医療機関の受診の際はマスクを着用し、公共交通機関の利用を控えて受診してください。</span><br />
            <br />
            <span>埼玉県受診・相談センター：０４８－７６２－８０２６</span><br />
            <span>埼玉県県民サポートセンター：０５７０－７８３－７７０</span><br />
          </div>
          <div className="general-info" style={{ textAlign: 'center', borderCollapse: 'collapse', marginTop: '10px' }}>
            <p>
              埼玉県指定 診療・検査医療機関を探す方は<br />
              こちらの二次元コードをお使いください
            </p>
            <div style={{ textAlign: 'center' }}>
              <img className="kakuin-all" src={`${process.env.PUBLIC_URL}/${'qr_saitama.png'}`} alt="qr code"></img>
            </div>
          </div>
        </div>
      )}

      {/* 無料検査 愛知 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("愛知県") != -1
        && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・この検査結果は、「ワクチン・検査パッケージ制度」等においてのみ有効です。</span><br />
              <span className="">・入店・入場の際に身分証とともに提示してください。</span><br />
              <span className="">・本検査結果通知書は、新型コロナ感染症の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <small className="" style={{ textAlign: 'left' }}>陽性の場合は、入場・入店できません。公共交通機関を避け速やかに医療機関を受診してください。</small>
          </div>
          <div className="main-info">
            <span className="name">受検者氏名：{props.client_full_name}</span><br />
            <span className="">検体採取日：{props.client_sampling_date}</span><br />
            <span className="">検査日：{props.client_analysis_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', paddingLeft: '5px'  }}>
              <span className="">検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px', paddingLeft:'5px' }}>
              <span className="">事業所名(又は検査所名)：帝国衛生検査所</span><br />
              <span className="">事業所電話番号：050-3101-7430</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            <small className="">※1 判定不能は陰性として扱うことはできないため、再度検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※２有効期限：ＰＣＲ検査等は採取日＋３日、抗原定性検査は検査日＋１日</small><br />
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
          <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
            <span className="">□ 速やかに医療機関を受診してください。</span><br />
            <span className="">□ 速やかに受診・相談センターに電話し、受診先について相談してください。</span><br />
            <span className="">https://www.pref.aichi.jp/site/covid19-aichi/soudan.html#jusinnsoudann</span><br />
          </div>
        </div>
      )}

      {/* 無料検査 岡山 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("岡山県") != -1
        && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・本検査結果通知書は、新型コロナ感染症の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
          </div>
          <div className="main-info">
            <span className="name">受検者氏名：{props.client_full_name}</span><br />
            <span className="">検体採取日：{props.client_sampling_date}</span><br />
            <span className="">検査日：{props.client_analysis_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', paddingLeft: '5px'  }}>
              <span className="">検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px', paddingLeft:'5px' }}>
              <span className="">事業所名(又は検査所名)：帝国衛生検査所</span><br />
              <span className="">事業所電話番号：050-3101-7430</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            <small className="">※1 判定不能は陰性として扱うことはできないため、再度検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※２ 有効期限：ＰＣＲ検査等は採取日＋３日、抗原定性検査は検査日＋１日</small><br />
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
          <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
            <span className="">□ この検査結果をもって、新型コロナウイルス感染症の感染者であることを確定するものではありませんが、感染者である可能性が高いことを示しています。</span><br />
            <span className="">□ 検査結果に不安のある方や発熱、咳、のどの痛みなどの症状が出た方は、まずは、かかりつけ医か「診療・検査医療機関」（発熱外来）に電話でご相談ください。<br />なお、受診にあたっては、現在救急外来は大変ひっ迫していますので、できるだけ平日の日中にご相談ください。<br />受診することとなった場合は、医療機関の指示に従うとともに、必ずマスクを着用してください。<br />受診し、検査結果がでるまでは公共交通機関の利用は控えてください。</span><br />
            <span className="">□ 感染拡大を防ぐため、症状が出ない場合でも７日間は、帰省、旅行、イベント参加等は控えてください。</span><br />
            <span className="">□ 特に、高齢者や基礎疾患のある方（慢性閉塞性肺疾患、慢性腎臓病、糖尿病、高血圧、心血管疾患、肥満BMI30以上等の方や妊婦の方など）など重症化リスクの高い方との接触や、密閉・密集・密接した場所へ行ったり、マスクを外して友人等と会食するなど感染させるリスクの高い行動は控えてください。</span><br /><br />
            <span className="">診療・検査医療機関(発熱外来)はコチラ</span><br />
            <div className="">
              <img className="kakuin-all" src={`${process.env.PUBLIC_URL}/${'qr_okayama.png'}`} alt="qr code"></img>
            </div>
          </div>
        </div>
      )}

      {/* 無料検査 静岡 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("静岡県") != -1
        && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・この検査結果は、「ワクチン・検査パッケージ制度」等においてのみ有効です。</span><br />
              <span className="">・入店・入場の際に身分証とともに提示してください。</span><br />
              <span className="">・本検査結果通知書は、新型コロナ感染症の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <small className="" style={{ textAlign: 'left' }}>陽性の場合は、入場・入店できません。公共交通機関を避け速やかに医療機関を受診してください。</small>
          </div>
          <div className="main-info">
            <span className="name">受検者氏名：{props.client_full_name}</span><br />
            <span className="">検体採取日：{props.client_sampling_date}</span><br />
            <span className="">検査日：{props.client_analysis_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', paddingLeft: '5px'  }}>
              <span className="">検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px', paddingLeft:'5px' }}>
              <span className="">事業所名(又は検査所名)：帝国衛生検査所</span><br />
              <span className="">事業所電話番号：050-3101-7430</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            <small className="">※1 判定不能は陰性として扱うことはできないため、再度検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※2 有効期限：ＰＣＲ検査等は採取日＋３日、抗原定性検査は検査日＋１日</small><br />
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
          <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
            <span className="">□ かかりつけ医等の医療機関に電話のうえ、受診してください。</span><br />
            <span className="">静岡県内の発熱等診療医療機関一覧</span><br />
            <span className="">https://www.pref.shizuoka.lg.jp/kousei/ko-420a/kansen/new-cov.html#hatunetu</span><br />
            <span className="">□ 発熱等受診・相談センターに電話し、受診先について相談してください。</span><br />
            <span className="">（静岡市・浜松以外にお住まいの方）静岡県発熱等受診相談センター</span><br />
            <span className="">電話番号:０５０（５３７１）０５６１</span><br />
            <span className="">（静岡市）静岡市保健所　電話番号:０５４（２４９）２２２１</span><br />
            <span className="">（浜松市）浜松市保健所　電話番号:０１２０（３６８）５６７</span><br />
          </div>
        </div>
      )}

      {/* 無料検査 滋賀 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("滋賀県") != -1
        && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・この検査結果は、「ワクチン・検査パッケージ制度」等においてのみ有効です。</span><br />
              <span className="">・入店・入場の際に身分証とともに提示してください。</span><br />
              <span className="">・本検査結果通知書は、新型コロナ感染症の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <small className="" style={{ textAlign: 'left' }}>陽性の場合は、入場・入店できません。公共交通機関を避け速やかに医療機関を受診してください。</small>
          </div>
          <div className="main-info">
            <span className="name">受検者氏名：{props.client_full_name}</span><br />
            <span className="">検体採取日：{props.client_sampling_date}</span><br />
            <span className="">検査日：{props.client_analysis_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', paddingLeft: '5px'  }}>
              <span className="">検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px', paddingLeft:'5px' }}>
              <span className="">事業所名(又は検査所名)：帝国衛生検査所</span><br />
              <span className="">事業所電話番号：050-3101-7430</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            <small className="">※1 判定不能は陰性として扱うことはできないため、再度検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※2 有効期限：ＰＣＲ検査等は採取日＋３日、抗原定性検査は検査日＋１日</small><br />
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
          <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
            <span className="">□ お近くの医療期間に電話当でご連絡の上、受診および受験してください。</span><br />
            <span className="">※なお、滋賀県ホームページに医療期間受診に関する情報お掲載しておりますので、必要に応じてご確認下さい。</span><br />
            <span className="">滋賀県ホームページ：発熱などの症状がある場合の相談・受診について</span><br />
            <span className="">　　URL:http://www.pref.shiga.lg.jp/ippan/kenkouiryouhukushi/iryo/314835.html</span><br />
            <div className="">
            <img className="kakuin-all" src={`${process.env.PUBLIC_URL}/${'qr_shiga.png'}`} alt="qr code"></img>
           </div>
          </div>
        </div>
      )}
      {/* 無料検査 岐阜 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("岐阜県") != -1
        && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・この検査結果は、「ワクチン・検査パッケージ制度」等においてのみ有効です。</span><br />
              <span className="">・入店・入場の際に身分証とともに提示してください。</span><br />
              <span className="">・本検査結果通知書は、新型コロナ感染症の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <small className="" style={{ textAlign: 'left' }}>陽性の場合は、入場・入店できません。公共交通機関を避け速やかに医療機関を受診してください。</small>
          </div>
          <div className="main-info">
            <span className="name">受検者氏名：{props.client_full_name}</span><br />
            <span className="">検体採取日：{props.client_sampling_date}</span><br />
            <span className="">検査日：{props.client_analysis_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', paddingLeft: '5px'  }}>
              <span className="">検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px', paddingLeft:'5px' }}>
              <span className="">事業所名(又は検査所名)：帝国衛生検査所</span><br />
              <span className="">事業所電話番号：050-3101-7430</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            <small className="">※1 判定不能は陰性として扱うことはできないため、再度検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※2 有効期限：ＰＣＲ検査等は採取日＋３日、抗原定性検査は検査日＋１日</small><br />
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
          <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
            <span className="">発熱などの症状がある場合は、こちらをご覧ください。</span><br />
            <span className="">発熱等の症状がある場合の相談・受診方法（https://www.pref.gifu.lg.jp/site/covid19/25920.html）</span><br />
            <span className="">無料検査の結果、陽性判定が出た場合は、こちらをご覧ください。</span><br />
            <span className="">無料検査で陽性判定となったみなさまへ（https://www.pref.gifu.lg.jp/site/covid19/200090.html）</span><br />
          </div>
        </div>
      )}

      {/* 無料検査 福岡 */}
      {props.client_certificate_type == 4 && props.client_store_pref.indexOf("福岡県") != -1
        && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">検査結果通知書</h2>
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px' }}>
              <span className="">・この検査結果は、「ワクチン・検査パッケージ制度」等においてのみ有効です。</span><br />
              <span className="">・入店・入場の際に身分証とともに提示してください。</span><br />
              <span className="">・本検査結果通知書は、新型コロナ感染症の患者であるかどうかの診断結果を示すものではありません。</span>
            </div>
            <small className="" style={{ textAlign: 'left' }}>陽性の場合は、入場・入店できません。公共交通機関を避け速やかに医療機関を受診してください。</small>
          </div>
          <div className="main-info">
            <span className="name">受検者氏名：{props.client_full_name}</span><br />
            <span className="">検体採取日：{props.client_sampling_date}</span><br />
            <span className="">検査日：{props.client_analysis_date}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', paddingLeft: '5px'  }}>
              <span className="">検査結果：{props.client_result_bool == 1 ? "陽性" : "陰性"}</span><br />
              <span className="">有効期限：{props.client_inspection_type == 5 ? "検体採取日+1日" : "検体採取日+3日"}</span>
            </div>
            <span className="">検査方法：{props.client_inspection_type == 5 ? "抗原定性検査" : "PCR検査等"}</span><br />
            <span className="">検体：{props.client_inspection_type == 5 ? "鼻腔ぬぐい液" : "唾液"}</span><br />
            <span className="">使用した検査試薬又は検査キット名：{props.client_inspection_type == 5 ? "SARS-CoV-2 Rapid Antigen Test" : "SARS-CoV-2 Direct Detection RT-qPCR Kit"}</span><br />
            <div style={{ textAlign: 'left', border: 'solid', borderWidth: '1px', marginTop: '10px', paddingLeft:'5px' }}>
              <span className="">事業所名(又は検査所名)：帝国衛生検査所</span><br />
              <span className="">事業所電話番号：050-3101-7430</span><br />
              <span className="">検査管理者氏名：近都 真侑</span>
            </div>
            <small className="">※1 判定不能は陰性として扱うことはできないため、再度検査を受けてください。その際、適宜検査の申込みをした事業者等とご相談ください。</small><br />
            <small className="">※2 有効期限：ＰＣＲ検査等は採取日＋３日、抗原定性検査は検査日＋１日</small><br />
          </div>
          <div className="general-info" style={{ textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}>
          <span style={{ textAlign: 'left', color: 'red' }}>【陽性の場合】</span><br />
            <span className="">医師による診断を受けるため、検査所からの案内に従い、</span><br />
            <span className="">□PCR検査の場合</span><br />
            <span className="">事前に連絡をした上で医療機関を受診または専用フォームから「陽性者登録」(要件あり)</span><br />
            <span className="">□抗原定性検査の場合</span><br />
            <span className="">事前に連絡をした上で医療機関を受診してください。</span><br />
          </div>
        </div>
      )}
      {/* 通常陰性証明書 */}
      {props.client_certificate_type == 1 && (
        <div className="sheet padding-10mm">
          <div className="contents_ja">
            <h2 className="title_ja">COVID-19に関する検査証明</h2>
            <h2 className="title_en">Certificate of Testing for COVID-19</h2>
            <p className="issue-date">交付年月日 (Issue Date) {props.client_analysis_date}</p>
            <p className="text-ja">COVID-19に関する検査を行ったところ、以下の結果が認められた。</p>
          </div>
          <div className="contents_en">
            <p className="text-en">
              The following COVID-19 test results have been confirmed through samples collected from the individual below.
            </p>
          </div>
          <div className="cus-info">
            <span className="name">● 氏名(Name)：{props.client_full_name}</span><br />
            <span className="dob">● 生年月日 (DOB)：{props.client_dob.substr(0, 4) + '/' + props.client_dob.substr(4, 2) + '/' + props.client_dob.substr(6, 2)}</span><br />
            <span className="sex">● 性別 (Sex)：{props.client_sex == 1 ? '男 Male' : '女 Female'}</span><br />
            <span className="">● 有効期限 (Period of Validity)：検体採取日{props.client_inspection_type == 6 ? "＋１日" : "＋３日"}<br />　　　　　　　　　　　　　　　　({props.client_inspection_type == 6 ? "1 day" : "3 days"}  after sampling date)</span>

            {/* {props.client_passport_number != "" && (
              <br />
            )}
            {props.client_passport_number != "" && (
              <span className="">● パスポート番号：{props.client_passport_number}</span>
            )}

            {props.client_nation != "" && (
               <br />
            )}
            {props.client_nation != "" && (
               <span className="">● 国籍：{props.client_nation}</span>
            )}

            <span className="">● 発行時刻：</span> */}

          </div>
          <div className="result">
            <table border="1">
              <thead>
              <tr>
                <th>採取検体<br />Sample</th>
                <th>検査法<br />Test Method</th>
                <th>結果<br />Result</th>
                <th>分析年月日<br />Analysis Date</th>
                <th>検体採取日<br />Sampling Date</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                {props.client_inspection_type == 6 ? (<td>鼻腔ぬぐい液<br />Nasal Swab</td>) : (<td>唾液<br />Saliva</td>)}
                {props.client_inspection_type == 6 ? (<td className="t-left">抗原定性検査<br />Qualitative Antigen Test</td>) : (<td className="t-left">核酸増幅検査<br />(Real-time RT-PCR法)<br />Nucleic acid amplification test<br />(Real-time RT-PCR)</td>)}
                {/* NOTE::文字列の比較だとなぜか上手くいかない */}
                {props.client_result_bool == 1 ? (<td>陽性<br />Positive</td>) : (<td>陰性<br />Negative</td>)}
                <td>分析年月日<br />{props.client_analysis_date}</td>
                <td>
                  検体採取日<br />
                  {props.client_sampling_date}
                  {props.client_need_time == true && (
                    <br />
                  )}
                  {props.client_need_time == true && (
                    <span className="">{props.client_nation}</span>
                  )}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="clinic-info">
            {props.client_certificate_clinic_id == 1 && new Date(props.client_analysis_date) >= new Date('2023/11/06 00:00:00') && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】川崎検査クリニック<br />【Name of Institution】Kawasaki Kensa Clinic</p>
                <p>【発行施設住所】〒210-0006 神奈川県川崎市川崎区砂子2-1-7 2F<br />【Address of Institution】2-1-7 2F Isago, Kawasaki-ku, Kawasaki-City, Kanagawa-Pref, 210-0006, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 1 && new Date(props.client_analysis_date) < new Date('2023/11/06 00:00:00') && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】川崎駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19</p>
                <p>【発行施設住所】〒210-0006 神奈川県川崎市川崎区砂子2丁目1-7<br />【Address of Institution】2-1-7 Isaga, Kawasaki-ku, Kawasaki-City, Kanagawa-Pref, 210-0006, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 2 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】藤沢駅前新型コロナPCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Fujisawa</p>
                <p>【発行施設住所】〒251-0055 神奈川県藤沢市南藤沢20-2<br />【Address of Institution】20-2 Minami-Fujisawa, Fujisawa-City, Kanagawa-Pref, 251-0055, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 3 && props.client_inspection_type != 6 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】横浜新型コロナPCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yokohama</p>
                <p>【発行施設住所】〒231-0057 神奈川県横浜市中区曙町3-40<br />【Address of Institution】3-40 Akebono-Cho, Naka-Ku, Yokohama-City, Kanagawa-Pref, 231-0057, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 3 && props.client_inspection_type == 6 && (
              <div className="clinic-info-wrapper-renmei">
                <p>【発行施設名】横浜新型コロナPCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yokohama<br />【発行施設住所】〒231-0057 神奈川県横浜市中区曙町3-40<br />【Address of Institution】3-40 Akebono-Cho, Naka-Ku, Yokohama-City, Kanagawa-Pref, 231-0057, JAPAN</p>
                <p>【発行施設名】武蔵小杉新丸子駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Musashikosugi<br />【発行施設住所】〒211-0005 神奈川県川崎市中原区新丸子町648-1<br />【Address of Institution】648-1, Shinmaruko-cho, Nakahara-ku, Kawasaki-city Kanagawa-Pref, 211-0005, JAPAN</p>
                <p>【発行施設名】平塚駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Hiratsuka<br />【発行施設住所】〒254-0034 神奈川県平塚市宝町2-2 T&amp;T NAKANOビルB1<br />【Address of Institution】B1 T&amp;T Nakano-building, Takara-cho 2-2, Hiratsuka-city Kanagawa-Pref, 254-0034, JAPAN</p>
                <p>【発行施設名】大和駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yamato<br />【発行施設住所】〒242-0017 神奈川県大和市大和東3-1-18 武井ビル1階<br />【Address of Institution】Takei-building 1F, Yamato-higashi 3-1-18, Yamato-city Kanagawa-Pref, 242-0017, JAPAN</p>
                <p>【発行施設名】横須賀中央駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yokosuka<br />【発行施設住所】〒238-0008 神奈川県横須賀市大滝町1-9 品川ビル102<br />【Address of Institution】Shinagawa 3-1-4,Ohtaki-cho1-9 Yokosuka-city, Kanagawa-Pref, 238-0008, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 4 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】武蔵小杉新丸子駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Musashikosugi</p>
                <p>【発行施設住所】〒211-0005 神奈川県川崎市中原区新丸子町648-1<br />【Address of Institution】648-1, Shinmaruko-cho, Nakahara-ku, Kawasaki-city Kanagawa-Pref, 211-0005, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 5 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】川崎検査クリニック上野院<br />【Name of Institution】Kawasaki Kensa Clinic Uenoin</p>
                <p>【発行施設住所】〒110-0005 東京都台東区上野4丁目5-11<br />【Address of Institution】#2F Yasutomi Building,4-5-11,UenoTaito-kuTokyo, 110-0005, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 6 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】平塚駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Hiratsuka</p>
                <p>【発行施設住所】〒254-0034 神奈川県平塚市宝町2-2 T&amp;T NAKANOビルB1<br />【Address of Institution】B1 T&amp;T Nakano-building, Takara-cho 2-2, Hiratsuka-city Kanagawa-Pref, 254-0034, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 7 && props.client_inspection_type != 6 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】市川本八幡駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Ichikawa</p>
                <p>【発行施設住所】〒272-0021 千葉県市川市八幡2-15-17<br />【Address of Institution】Yawata 2-15-17, Ichikawa-city Chiba-Pref, 272-0021, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 7  && props.client_inspection_type == 6 && (
              <div className="clinic-info-wrapper-renmei">
                <p>【発行施設名】市川本八幡駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Ichikawa<br />【発行施設住所】〒272-0021 千葉県市川市八幡2-15-17<br />【Address of Institution】Yawata 2-15-17, Ichikawa-city Chiba-Pref, 272-0021, JAPAN</p>
                <p>【発行施設名】松戸駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Matsudo<br />【発行施設住所】〒271-0091 千葉県松戸市本町1-12 日発ビル４Ｆ<br />【Address of Institution】Honchou 1-12, Matsudo-city Chiba-Pref, 271-0091, JAPAN</p>
                <p>【発行施設名】五井駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Goi<br />【発行施設住所】〒290-0081 千葉県市原市五井中央西1-21-19 岡田ビル1階A<br />【Address of Institution】Goichuuounishi 1-21-19, Ichihara-city Chiba-Pref, 290-0081, JAPAN</p>
                <p>【発行施設名】木更津駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kisarazu<br />【発行施設住所】〒292-0831 千葉県木更津市富士見1-2-1 スパークルシティ木更津Ｂ館1-1区画<br />【Address of Institution】Fujimi 1-2-1, Kisarazu-city Chiba-Pref, 292-0831, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 8 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】大和駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yamato</p>
                <p>【発行施設住所】〒242-0017 神奈川県大和市大和東3-1-18 武井ビル1階<br />【Address of Institution】Takei-building 1F, Yamato-higashi 3-1-18, Yamato-city Kanagawa-Pref, 242-0017, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 9 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】渋谷駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Shibuya</p>
                <p>【発行施設住所】〒150-0042 東京都渋谷区宇田川町26-6 宇田川町ダイタンビル1階-3階<br />【Address of Institution】Udagawa DaitanBuilding 1F-3F, Udagawa-cho 26-6, Shibuya-ku Tokyo, 150-0042, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 10 && props.client_inspection_type != 6 &&  (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】赤羽駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Akabane</p>
                <p>【発行施設住所】〒115-0045 東京都 北区赤羽1-36-10 サンヨー本社ビルB1F<br />【Address of Institution】SanyoHonsha-building B1F, Akabane1-36-10, Kita-ku Tokyo, 115-0045, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 10 && props.client_inspection_type == 6 &&  (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19 by Kawasaki Kensa Clinic</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 11 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】浦和駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Urawa</p>
                <p>【発行施設住所】〒330-0063 埼玉県さいたま市浦和区高砂2-2-2 渡辺ビル2階<br />【Address of Institution】Watanabe building 2F, Takasago 2-2-2, Urawa-ku, Saitama-city, Saitama 330-0063, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 12 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】六本木漢方薬局PCRセンター<br />【Name of Institution】PCR Testing Center for COVID-19, Roppongi</p>
                <p>【発行施設住所】〒106-0032 港区六本木3-15-24アリエスビル1階<br />【Address of Institution】Ariesu-building 1F, Roppongi3-15-24, Minato-ku Tokyo, 106-0032, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 13 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】川越駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kawagoe</p>
                <p>【発行施設住所】〒350-0043 埼玉県川越市新富町2-2-7ハーモニーハウス1階<br />【Address of Institution】Harmony house 1F, Shintomi-cho 2-2-7, Kawagoe-city, Saitama 350-0043, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 14 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】巣鴨駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Sugamo</p>
                <p>【発行施設住所】〒170-0002 東京都豊島区巣鴨1丁目19-12 はい原ビル1階<br />【Address of Institution】Haihara-building 1F, Sugamo 1-19-12, Toshima-ku Tokyo, 170-0002, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 15 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】桜新町駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Sakurashinmachi</p>
                <p>【発行施設住所】〒154-0015 東京都世田谷区桜新町1-8-14-1階<br />【Address of Institution】Sakurashinmachi 1-8-14, Setagaya-ku Tokyo, 154-0025, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 16 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】麹町駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kojimachi</p>
                <p>【発行施設住所】〒102-0093 東京都千代田区平河町1丁目4-5 地下1階 平和第1ビル<br />【Address of Institution】1-4-5,Hirakawacho,Chiyoda-ku,Tokyo102-0093 Japan</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 17 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】BIOTOPE クリニック<br />【Name of Institution】BIOTOPE CLINIC</p>
                <p>【発行施設住所】〒108-0071 東京都港区白金台4丁目9-10 グリーンリーブス 2F<br />【Address of Institution】Green-leaves 2F, Shirokanedai4-9-10, Minato-ku Tokyo, 108-0071, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 18 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】国分寺駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kokubunji</p>
                <p>【発行施設住所】〒185-0012 東京都国分寺市本町３丁目６−１４ 峯岸ビル1F<br />【Address of Institution】Minegishi building 1F, Honcho 3-6-14, Kokubunji-city Tokyo, 185-0012, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 19 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】飯田橋駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Iidabashi</p>
                <p>【発行施設住所】〒162-0822 東京都新宿区下宮比町2-30 小林ビル1階<br />【Address of Institution】Kobayashi building 1F, Shimomiyabi-cho 2-30, Shinjuku-ku, Tokyo, 162-0822, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 20 && (
              <div className="clinic-info-wrapper">
              <p>【発行施設名】茅場町駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kayabacho</p>
              <p>【発行施設住所】〒104-0033 東京都中央区新川1-19-10 アルファスタイル茅場町１階<br />【Address of Institution】AlphaStyle Kayabacho 1F, Shinkawa 1-19-10, Chuo-ku Tokyo, 104-0033, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 21 && (
              <div className="clinic-info-wrapper">
              <p>【発行施設名】鶴見駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Tsurumi</p>
              <p>【発行施設住所】〒230-0061 神奈川県横浜市鶴見区佃野町２２−１３ドミール鶴見2階<br />【Address of Institution】Domir Tsurumi 2F, Tsukuno-cho 22-13,Tsurumi-ku, Yokohama-city Kanagawa-Pref, 230-0061, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 22 && (
              <div className="clinic-info-wrapper">
              <p>【発行施設名】町田駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Machida</p>
              <p>【発行施設住所】〒194-0013 東京都町田市原町田6-21-24 松沢ビル2階<br />【Address of Institution】Matsuzawa building 2F, Haramachida 6-21-24, Machida-city, Tokyo, 194-0013, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 23 && (
              <div className="clinic-info-wrapper">
              <p>【発行施設名】学芸大学PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Gakugeidaigaku</p>
                <p>【発行施設住所】〒152-0004 東京都目黒区鷹番3-3-18<br />【Address of Institution】Takaban 3-3-18, Meguro-ku Tokyo, 152-0004, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 24 && (
              <div className="clinic-info-wrapper">
              <p>【発行施設名】センター北駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Center-kita</p>
              <p>【発行施設住所】〒224-0003 神奈川県横浜市都筑区中川中央1丁目39-44-202<br />【Address of Institution】Nakagawachuo1-39-44-202,Tsuzuki-ku, Yokohama-city, Kanagawa-Pref, 224-0003, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 25 && (
              <div className="clinic-info-wrapper">
              <p>【発行施設名】麻布十番駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Azabujuban</p>
              <p>【発行施設住所】〒106-0045 東京都港区麻布十番3丁目11-10パークハウス麻布十番アーバンス1F<br />【Address of Institution】Parkhouse Azabujuban Arbance 1F, Azabujuban 3-11-10, Minato-ku Tokyo, 106-0045, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 26 && (
              <div className="clinic-info-wrapper">
              <p>【発行施設名】駒込駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Komagome</p>
              <p>【発行施設住所】〒170ｰ0003 東京都豊島区駒込3-3-21メリービルB1<br />【Address of Institution】Mery building B1, Komagome 3-3-21, Teshima-ku Tokyo, 170ｰ0003, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 27 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】松戸駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Matsudo</p>
                <p>【発行施設住所】〒271-0091 千葉県松戸市本町1-12 日発ビル４Ｆ<br />【Address of Institution】Honchou 1-12, Matsudo-city Chiba-Pref, 271-0091, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 28 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】立川駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Tachikawa</p>
                <p>【発行施設住所】〒190-0023 東京都立川市柴崎町3-7-17立川Ｎ2ビル1階<br />【Address of Institution】Tachikawa N2 building 1F, Shibasakicho 3-7-17, Tachikawa-city Tokyo, 190-0023, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 29 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】中野駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Nakano</p>
                <p>【発行施設住所】〒164-0001 東京都中野区中野2-30-13 1階<br />【Address of Institution】1F, Nakano 2-30-13, Nakano-ku Tokyo, 164-0001, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 30 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】五井駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Goi</p>
                <p>【発行施設住所】〒290-0081 千葉県市原市五井中央西1-21-19 岡田ビル1階A<br />【Address of Institution】Goichuuounishi 1-21-19, Ichihara-city Chiba-Pref, 290-0081, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 31 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】春日部駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kasukabe</p>
                <p>【発行施設住所】〒344-0067 埼玉県春日部市中央1-10-15 第四タカノビル1階<br />【Address of Institution】TakanoBuilding4 1F, Chuo1-10-15, Kasukabe-city Saitama-Pref, 344-0067, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 32 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】鴨居駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kamoi</p>
                <p>【発行施設住所】〒226-0003 神奈川県横浜市緑区鴨居3-1-4 鴨居ユニオンビル1F<br />【Address of Institution】KamoiUnionBuildingKamoi 3-1-4,Midori-ku, Yokohama-city, Kanagawa-Pref, 226-0003, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 33 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】横須賀中央駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yokosuka</p>
                <p>【発行施設住所】〒238-0008 神奈川県横須賀市大滝町1-9 品川ビル102<br />【Address of Institution】Shinagawa 3-1-4,Ohtaki-cho1-9 Yokosuka-city, Kanagawa-Pref, 238-0008, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 34 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】本厚木駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Honatsugi</p>
                <p>【発行施設住所】〒243-0018 神奈川県厚木市中町2-8-10加瀬ビル170 1階<br />【Address of Institution】Kase building 170 ,Naka-cho 2-8-10 Atsugi-city, Kanagawa-Pref, 243-0018, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 35 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】千葉中央駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Chibachuo</p>
                <p>【発行施設住所】〒260-0013 千葉県千葉市中央区中央2-2-8秀和ビル地下1階<br />【Address of Institution】Shuwa-Building B1, Chuo2-2-8, Chuo-ku, Chiba-city Chiba-Pref, 260-0013, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 36 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】木更津駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kisarazu</p>
                <p>【発行施設住所】〒292-0831 千葉県木更津市富士見1-2-1 スパークルシティ木更津Ｂ館1-1区画<br />【Address of Institution】Fujimi 1-2-1, Kisarazu-city Chiba-Pref, 292-0831, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 37 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】千葉船橋駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, ChibaFunabashi</p>
                <p>【発行施設住所】〒273-0005 千葉県船橋市本町1丁目 8-2 Shoto壱番館 1階<br />【Address of Institution】Shoto Ichibankan 1F, Honcho 1-8-2, Funabashi-city Chiba-Pref,273-0005, JAPAN</p>
            </div>
            )}
            {props.client_certificate_clinic_id == 38 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】大森駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Omori</p>
                <p>【発行施設住所】〒143-0023 東京都大田区山王町2-3-8芳花園ビル1階<br />【Address of Institution】HokaenBilding1F, Sanno-cho 2-3-8, Oota-ku Tokyo, 143-0023, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 39 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】目黒駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Meguro</p>
                <p>【発行施設住所】〒153-0063 東京都目黒区目黒1-5-17マツヤ第一ビル1階2階<br />【Address of Institution】Matsuya First Bilding1F 2F, 1-5-17, Meguro, Meguro-ku, Tokyo, 153-0063, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 40 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】練馬駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Nerima</p>
                <p>【発行施設住所】〒176-000 東京都練馬区練馬1-1-4 武内ビル1階<br />【Address of Institution】Takeuchi Bilding1F, 1-1-4, Nerima, Nerima-ku, Tokyo, 176-0001, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 41 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】大岡山駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Ookayama</p>
                <p>【発行施設住所】〒145-006 東京都大田区北千束1丁目45-12 清風堂ビル1F<br />【Address of Institution】Seifudo Bilding1F, 1-45-12, Kitasenzoku, Ota-ku, Tokyo, 145-0062, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 42 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】府中駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Fuchu</p>
                <p>【発行施設住所】〒183-0056 東京都府中市寿町1-6-2 ことぶきマンション1階<br />【Address of Institution】Kotobuki Mansion1F, 1-6-2, Kotobuki-city, Fuchu-Shi, Tokyo, 183-0056, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 43 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】四谷駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yotsuya</p>
                <p>【発行施設住所】〒160-0004 東京都新宿区四谷1-9-3新盛ビル1階<br />【Address of Institution】Sinmori Bilding1F, 1-9-3, Yotsuya, Shinjuku-ku, Tokyo, 160-0004, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 44 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】八王子駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Hachioji</p>
                <p>【発行施設住所】〒192-0082 東京都八王子東町12-14 八王子ステーション1階<br />【Address of Institution】Hachiouzi Station1F, 12-14, Azuma-city, Hachioji, Tokyo, 192-0082, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 46 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】辻堂PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Tsujido</p>
                <p>【発行施設住所】〒251-0041 神奈川県藤沢市辻堂神台1-5-1 湘南藤沢徳洲会病院内<br />【Address of Institution】Syounan Fuzisawa Tokusyuukai Hospital, 1-5-1, Tsujido Kandai, Fujisawa Shi, Kanagawa Ken, 251-0041, JAPAN </p>
              </div>
            )}
            {props.client_certificate_clinic_id == 45 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】川崎検査クリニック上野院<br />【Name of Institution】Kawasaki Kensa Clinic Uenoin</p>
                <p>【発行施設住所】〒110-0005 東京都台東区上野4丁目5-11<br />【Address of Institution】#2F Yasutomi Building,4-5-11,UenoTaito-kuTokyo, 110-0005, JAPAN</p>
              </div>
             )}
            {props.client_certificate_clinic_id == 47 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】浅草駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Asakusa</p>
                <p>【発行施設住所】〒111-0033 東京都台東区花川戸1-10-14LASPASIO浅草スカイビュースクエア1階<br />【Address of Institution】LASPASIO Asakusa Sky View Square 1F, 1-10-14, Hanakawado, Taito-ku, Tokyo, 111-0033, JAPAN </p>
              </div>
            )}
            {props.client_certificate_clinic_id == 48 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】鎌倉駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kamakura</p>
                <p>【発行施設住所】〒248-0012 神奈川県鎌倉市御成町13-40-1階<br />【Address of Institution】1F, 13-40, Onarimachi, Kamakura Shi, Kanagawa Ken, 248-0012, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 49 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】金山駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kanayama</p>
                <p>【発行施設住所】〒460-0022 愛知県名古屋市金山2-8-4 エスポア金山1F D号<br />【Address of Institution】Easupokanayama 1F D, 2-8-4, Kanayama, Nagoya-shi, Aichi-ken, 460-0022, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 50 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】一宮駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Ichinomia</p>
                <p>【発行施設住所】〒491-0858	愛知県一宮市栄3-6-7 中駒一宮駅東マンション1階<br />【Address of Institution】NakakomaichinomiyaekihigashiMansion 1F, 3-6-7, Sakae, Ichinomiya-shi, Aichi-ken, 491-0858, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 51 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】岡山駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Okayama</p>
                <p>【発行施設住所】〒700-0901	岡山県岡山市北区本町10-23 小竹ビル2階<br />【Address of Institution】KodakeBilding 2F, 10-23, Hommachi, Kita-ku, Okayama-shi, Okayama-ken, 700-0901, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 52 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】浜松駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Hamamatsu</p>
                <p>【発行施設住所】〒430-0926	静岡県浜松町中区砂山町353-3 大協土地ビル1階<br />【Address of Institution】DaikyotochiBilding 1F, 353-3, Sunayamacho, Naka-ku, Hamamatsu-shi, Shizuoka-ken, 430-0926 JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 53 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】倉敷駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kurashiki</p>
                <p>【発行施設住所】〒710-0055	岡山県倉敷市阿知3丁目3-11 金本ビル201<br />【Address of Institution】KanemotoBilding 201, 3-11, Achi, Kurashiki-shi, Okayama-ken, 710-0055, JAPAN</p>
              </div>
            )}
            {props.client_certificate_clinic_id == 54 && (
              <div className="clinic-info-wrapper">
                <p>【発行施設名】刈谷駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kariya</p>
                <p>【発行施設住所】〒448-0027	愛知県刈谷市相生町2-30-2TRUNK刈谷1階<br />【Address of Institution】 TRUNK Kariya 1F, 2-30-2, Aioicho, Kariya-shi, Aichi-ken, 448-0027, JAPAN</p>
              </div>
            )}

            {props.client_certificate_clinic_id == 55 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】豊橋駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Toyohashi</p>
                      <p>【発行施設住所】〒440-0888	愛知県豊橋市駅前大通1-12 宝ビル<br />【Address of Institution】 TakaraBilding 201, 1-12, Ekimaeodori, Toyohashi-shi, Aichi-ken, 440-0888, Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 56 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】草津駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kusatsu</p>
                      <p>【発行施設住所】〒525-0032	滋賀県草津市大路1-9-1 クロスアベニュークサツ<br />【Address of Institution】 Kurosuabenyu-kusatsu, 1-9-1 Oji, Kusatsu-shi, Shiga-ken, 525-0032, Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 57 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】岐阜駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Gifu</p>
                      <p>【発行施設住所】〒500-8833	岐阜県岐阜市神田町9-27 大岐阜ビル1階<br />【Address of Institution】DaigifuBilding1F , 9-27, Kandamachi, Gifu-shi, Gifu-ken, 500-8833, Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 58 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】名古屋駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Nagoya</p>
                      <p>【発行施設住所】〒448-0027	〇〇〇〇〇〇〇〇〇<br />【Address of Institution】 TRUNK Kariya 1F, 2-30-2, Aioicho, Kariya-shi, Aichi-ken, 448-0027, JAPAN</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 59 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】栄前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Sakaemae</p>
                      <p>【発行施設住所】〒448-0027	〇〇〇〇〇〇〇〇〇<br />【Address of Institution】 TRUNK Kariya 1F, 2-30-2, Aioicho, Kariya-shi, Aichi-ken, 448-0027, JAPAN</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 60 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】静岡駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Shizuoka</p>
                      <p>【発行施設住所】〒420-0857	静岡県静岡市葵区御幸町3-21ペガサート101<br />【Address of Institution】 Pegasart 101, 3-21 Miyukicho, Aoi-ku, Shizuoka-shi, Shizuoka-ken, 420-0857, Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 61 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】沼津駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Numazu</p>
                      <p>【発行施設住所】〒410-0056	静岡県沼津市高島町6-1アイエフ内田ビル1F<br />【Address of Institution】 IFUchidaBilding 1F, 6-1, Takashimacho, Numazu-shi, Shizuoka-ken, 410-0056, Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 62 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】川崎検査クリニック上野院<br />【Name of Institution】Kawasaki Kensa Clinic Uenoin</p>
                      <p>【発行施設住所】〒110-0005 東京都台東区上野4丁目5-11<br />【Address of Institution】#2F Yasutomi Building,4-5-11,UenoTaito-kuTokyo, 110-0005, JAPAN</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 63 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】豊田駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Toyota</p>
                      <p>【発行施設住所】〒471-0034	愛知県豊田市小坂本町4丁目6-2 2階<br />【Address of Institution】2F, 4-6-2 Kozakahommachi, Toyota-shi, Aichi-ken 471-0034 Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 64 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】南大塚無料検査場<br />【Name of Institution】PCR Testing Center for COVID-19, Minami-Otsuka</p>
                      <p>【発行施設住所】〒170-0005	東京都豊島区南大塚3-32-10<br />【Address of Institution】3-32-10 Minami-Otsuka, Toshima-ku, Tokyo 170-0005 Japan</p>
                      <p>【事業所名】株式会社ヒューマンプランニング<br />【Name of Company】Human Planning Co. Ltd.</p>
                      <p>【管理者名】大谷 柾樹<br />【Name of Administrator】Masaki Otani</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 65 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】赤坂駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Akasaka</p>
                      <p>【発行施設住所】〒810-0042 福岡県中央区赤坂1-9-2コンセントリクス福岡天神ビル1階<br />【Address of Institution】1F, 1-16-1 Kamikuzuhara, Kokuraminamiku, Kitakyushu-city, Fukuoka-ken, 800-0255, Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 66 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】小倉南区駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Kokuraminamiku</p>
                      <p>【発行施設住所】〒800-0255 福岡県北九州市小倉南区上葛原1-16-1-1階<br />【Address of Institution】1F, 1-16-1 Kamikuzuhara, Kokuraminamiku, Kitakyushu-city, Fukuoka-ken, 800-0255, Japan</p>
                    </div>
             )}
            {props.client_certificate_clinic_id == 67 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】日吉駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Hiyoshi</p>
                      <p>【発行施設住所】〒223-0061 神奈川県横浜市港北区日吉2-2-20<br />【Address of Institution】Sumitate hiyoshi ekimae-Building 1F-2F,2-2-20 Hiyoshi, Minatokita-ku,Yokohama-city, Kanagawa-Pref, 223-0061, Japan</p>
                    </div>
            )}
            {props.client_certificate_clinic_id == 68 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】横浜PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Yokohama</p>
                      <p>【発行施設住所】〒231-0057 神奈川県横浜市中区曙町3-40<br />【Address of Institution】3-40 Akebono-Cho, Naka-Ku, Yokohama-City, Kanagawa-Pref, 231-0057, JAPAN</p>
                    </div>
            )}
            {props.client_certificate_clinic_id == 76 && (
                    <div className="clinic-info-wrapper">
                      <p>【発行施設名】中央林間駅前PCR検査センター<br />【Name of Institution】PCR Testing Center for COVID-19, Chuorinkan</p>
                      <p>【発行施設住所】〒242-0007 神奈川県大和市中央林間4丁目 加藤ビル2階<br />【Address of Institution】2F Kato building 4 Chu-o Rinnkan,Yamato-city Kanagawa-Pref, 242-0007, JAPAN</p>
                    </div>
            )}
            <div className="clinic-info-wrapper">
              <p>【検査所名】帝国衛生検査所<br />【Name of Laboratory】Teikoku Inspection Center</p>
              <p>【検査管理者】近都　真侑<br />【Name of Administrator】Masayuki Kintsu</p>
            </div>
            {/* <div className="kakuin-wrapper">
              <img className="kakuin" src={`${process.env.PUBLIC_URL}/${props.kakuin_file}`} alt="角印"></img>
            </div> */}

            {/* 連盟対応 */}
            {(props.client_certificate_clinic_id == 3 || props.client_certificate_clinic_id == 7 || props.client_certificate_clinic_id == 10) && props.client_inspection_type == 6 ? (
              <div className="kakuin-wrapper">
                <img className="kakuin" src={`${process.env.PUBLIC_URL}/ekimae_kakuin.png`} alt="角印"></img>
              </div>)
              : (<div className="kakuin-wrapper">
                  <img className="kakuin" src={`${process.env.PUBLIC_URL}/${props.kakuin_file}`} alt="角印"></img>
                </div>)}
            {/* 連盟対応ここまで */}
          </div>
        </div>
      )}
    </div>
  );
};
